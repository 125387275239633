<template>
    <v-container fluid>
        <v-card flat light>
            <v-row align="center">
                <v-col cols="12" sm="6" class="d-flex justify-center">
                    <div>
                        <div class="display-2 font-weight-bold">{{ $t('oops') }}</div>
                        <p class="title">{{$t('access_to_the_page_is_denied')}}</p>
                        <p class="body-2">{{$t('you_do_not_have_permission_to_go_to_this_page')}}</p>
                        <router-link :to="{name: 'dashboard'}" class="btn btn-primary btn-hover-shine btn-transition btn-back">{{$t('back')}}</router-link>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-start">
                    <v-img src="/img/error_pages/403.gif" max-height="300" contain></v-img>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
    export default {
        name: "Forbidden"
    };

</script>
